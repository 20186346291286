/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import "~leaflet/dist/leaflet.css";
@import "../node_modules/leaflet/dist/leaflet.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '../node_modules/angular-archwizard/styles/archwizard.css';


.swiper-slide.swiper-slide-active {
  text-align: center!important;
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.popover-content.sc-ion-popover-md {
  width: auto;
}

:root {
  .popover-content.sc-ion-popover-md {
    width: auto;

    --ion-color-primary: #64c63f!important;
    --ion-color-primary-rgb: 100,198,63!important;
    --ion-color-primary-contrast: #ffffff!important;
    --ion-color-primary-contrast-rgb: 255,255,255!important;
    --ion-color-primary-shade: #58ae37!important;
    --ion-color-primary-tint: #74cc52!important;

    --ion-color-secondary: #42b3c2!important;
    --ion-color-secondary-rgb: 66,179,194!important;
    --ion-color-secondary-contrast: #ffffff!important;
    --ion-color-secondary-contrast-rgb: 255,255,255!important;
    --ion-color-secondary-shade: #3a9eab!important;
    --ion-color-secondary-tint: #55bbc8!important;
}

    --ion-color-otro: #3381ff;
    --ion-color-otro-rgb: 51,129,255;
    --ion-color-otro-contrast: #ffffff;
    --ion-color-otro-contrast-rgb: 255,255,255;
    --ion-color-otro-shade: #2d72e0;
    --ion-color-otro-tint: #478eff;
  }

  .ion-color-otro {
    --ion-color-base: var(--ion-color-otro);
    --ion-color-base-rgb: var(--ion-color-otro-rgb);
    --ion-color-contrast: var(--ion-color-otro-contrast);
    --ion-color-contrast-rgb: var(--ion-color-otro-contrast-rgb);
    --ion-color-shade: var(--ion-color-otro-shade);
    --ion-color-tint: var(--ion-color-otro-tint);
  }

  :root {
    --ion-color-indicador: #7a33ff;
    --ion-color-indicador-rgb: 122,51,255;
    --ion-color-indicador-contrast: #ffffff;
    --ion-color-indicador-contrast-rgb: 255,255,255;
    --ion-color-indicador-shade: #6b2de0;
    --ion-color-indicador-tint: #8747ff;
  }

  .ion-color-indicador {
    --ion-color-base: var(--ion-color-indicador);
    --ion-color-base-rgb: var(--ion-color-indicador-rgb);
    --ion-color-contrast: var(--ion-color-indicador-contrast);
    --ion-color-contrast-rgb: var(--ion-color-indicador-contrast-rgb);
    --ion-color-shade: var(--ion-color-indicador-shade);
    --ion-color-tint: var(--ion-color-indicador-tint);
  }

  :root {
    --ion-color-verde: #1BB63A;
    --ion-color-verde-rgb: 27,182,58;
    --ion-color-verde-contrast: #ffffff;
    --ion-color-verde-contrast-rgb: 255,255,255;
    --ion-color-verde-shade: #18a033;
    --ion-color-verde-tint: #32bd4e;
  }

  .ion-color-verde {
    --ion-color-base: var(--ion-color-verde);
    --ion-color-base-rgb: var(--ion-color-verde-rgb);
    --ion-color-contrast: var(--ion-color-verde-contrast);
    --ion-color-contrast-rgb: var(--ion-color-verde-contrast-rgb);
    --ion-color-shade: var(--ion-color-verde-shade);
    --ion-color-tint: var(--ion-color-verde-tint);
  }

  :root {
    --ion-color-liwa: #032843;
    --ion-color-liwa-rgb: 3,40,67;
    --ion-color-liwa-contrast: #ffffff;
    --ion-color-liwa-contrast-rgb: 255,255,255;
    --ion-color-liwa-shade: #03233b;
    --ion-color-liwa-tint: #1c3e56;
  }

  .ion-color-liwa {
    --ion-color-base: var(--ion-color-liwa);
    --ion-color-base-rgb: var(--ion-color-liwa-rgb);
    --ion-color-contrast: var(--ion-color-liwa-contrast);
    --ion-color-contrast-rgb: var(--ion-color-liwa-contrast-rgb);
    --ion-color-shade: var(--ion-color-liwa-shade);
    --ion-color-tint: var(--ion-color-liwa-tint);
  }

  // @media (prefers-color-scheme: dark) {

  //   /*
  //    * Dark Colors
  //    * -------------------------------------------
  //    */

  //   body {
  //     --ion-color-primary: #0fc3ff;
  //     --ion-color-primary-rgb: 15, 195, 255;
  //     --ion-color-primary-contrast: #000000;
  //     --ion-color-primary-contrast-rgb: 0, 0, 0;
  //     --ion-color-primary-shade: #0dace0;
  //     --ion-color-primary-tint: #27c9ff;

  //     --ion-color-secondary: #50c8ff;
  //     --ion-color-secondary-rgb: 80,200,255;
  //     --ion-color-secondary-contrast: #ffffff;
  //     --ion-color-secondary-contrast-rgb: 255,255,255;
  //     --ion-color-secondary-shade: #46b0e0;
  //     --ion-color-secondary-tint: #62ceff;

  //     --ion-color-tertiary: #273a7a;
  //     --ion-color-tertiary-rgb: 39,58,122;
  //     --ion-color-tertiary-contrast: #ffffff;
  //     --ion-color-tertiary-contrast-rgb: 255,255,255;
  //     --ion-color-tertiary-shade: #22336b;
  //     --ion-color-tertiary-tint: #3d4e87;

  //     --ion-color-success: #2fdf75;
  //     --ion-color-success-rgb: 47,223,117;
  //     --ion-color-success-contrast: #000000;
  //     --ion-color-success-contrast-rgb: 0,0,0;
  //     --ion-color-success-shade: #29c467;
  //     --ion-color-success-tint: #44e283;

  //     --ion-color-warning: #ffd534;
  //     --ion-color-warning-rgb: 255,213,52;
  //     --ion-color-warning-contrast: #000000;
  //     --ion-color-warning-contrast-rgb: 0,0,0;
  //     --ion-color-warning-shade: #e0bb2e;
  //     --ion-color-warning-tint: #ffd948;

  //     --ion-color-danger: #ff4961;
  //     --ion-color-danger-rgb: 255,73,97;
  //     --ion-color-danger-contrast: #ffffff;
  //     --ion-color-danger-contrast-rgb: 255,255,255;
  //     --ion-color-danger-shade: #e04055;
  //     --ion-color-danger-tint: #ff5b71;

  //     --ion-color-dark: #f4f5f8;
  //     --ion-color-dark-rgb: 244,245,248;
  //     --ion-color-dark-contrast: #000000;
  //     --ion-color-dark-contrast-rgb: 0,0,0;
  //     --ion-color-dark-shade: #d7d8da;
  //     --ion-color-dark-tint: #f5f6f9;

  //     --ion-color-medium: #989aa2;
  //     --ion-color-medium-rgb: 152,154,162;
  //     --ion-color-medium-contrast: #000000;
  //     --ion-color-medium-contrast-rgb: 0,0,0;
  //     --ion-color-medium-shade: #86888f;
  //     --ion-color-medium-tint: #a2a4ab;

  //     --ion-color-light: #222428;
  //     --ion-color-light-rgb: 34,36,40;
  //     --ion-color-light-contrast: #ffffff;
  //     --ion-color-light-contrast-rgb: 255,255,255;
  //     --ion-color-light-shade: #1e2023;
  //     --ion-color-light-tint: #383a3e;
  //   }

  //   /*
  //    * iOS Dark Theme
  //    * -------------------------------------------
  //    */

  //   .ios body {
  //     --ion-background-color: #000000;
  //     --ion-background-color-rgb: 0,0,0;

  //     --ion-text-color: #ffffff;
  //     --ion-text-color-rgb: 255,255,255;

  //     --ion-color-step-50: #0d0d0d;
  //     --ion-color-step-100: #1a1a1a;
  //     --ion-color-step-150: #262626;
  //     --ion-color-step-200: #333333;
  //     --ion-color-step-250: #404040;
  //     --ion-color-step-300: #4d4d4d;
  //     --ion-color-step-350: #595959;
  //     --ion-color-step-400: #666666;
  //     --ion-color-step-450: #737373;
  //     --ion-color-step-500: #808080;
  //     --ion-color-step-550: #8c8c8c;
  //     --ion-color-step-600: #999999;
  //     --ion-color-step-650: #a6a6a6;
  //     --ion-color-step-700: #b3b3b3;
  //     --ion-color-step-750: #bfbfbf;
  //     --ion-color-step-800: #cccccc;
  //     --ion-color-step-850: #d9d9d9;
  //     --ion-color-step-900: #e6e6e6;
  //     --ion-color-step-950: #f2f2f2;

  //     --ion-item-background: #000000;

  //     --ion-card-background: #1c1c1d;
  //   }

  //   .ios ion-modal {
  //     --ion-background-color: var(--ion-color-step-100);
  //     --ion-toolbar-background: var(--ion-color-step-150);
  //     --ion-toolbar-border-color: var(--ion-color-step-250);
  //   }


  //   /*
  //    * Material Design Dark Theme
  //    * -------------------------------------------
  //    */

  //   .md body {
  //     --ion-background-color: #121212;
  //     --ion-background-color-rgb: 18,18,18;

  //     --ion-text-color: #ffffff;
  //     --ion-text-color-rgb: 255,255,255;

  //     --ion-border-color: #222222;

  //     --ion-color-step-50: #1e1e1e;
  //     --ion-color-step-100: #2a2a2a;
  //     --ion-color-step-150: #363636;
  //     --ion-color-step-200: #414141;
  //     --ion-color-step-250: #4d4d4d;
  //     --ion-color-step-300: #595959;
  //     --ion-color-step-350: #656565;
  //     --ion-color-step-400: #717171;
  //     --ion-color-step-450: #7d7d7d;
  //     --ion-color-step-500: #898989;
  //     --ion-color-step-550: #949494;
  //     --ion-color-step-600: #a0a0a0;
  //     --ion-color-step-650: #acacac;
  //     --ion-color-step-700: #b8b8b8;
  //     --ion-color-step-750: #c4c4c4;
  //     --ion-color-step-800: #d0d0d0;
  //     --ion-color-step-850: #dbdbdb;
  //     --ion-color-step-900: #e7e7e7;
  //     --ion-color-step-950: #f3f3f3;

  //     --ion-item-background: #1e1e1e;

  //     --ion-toolbar-background: #1f1f1f;

  //     --ion-tab-bar-background: #1f1f1f;

  //     --ion-card-background: #1e1e1e;
  //   }
  // }

  /* Importing Bootstrap SCSS file. */
  @import '~bootstrap/scss/bootstrap';
