
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

@font-face {
  font-family: "AppFont";
  //src: url("../assets/fonts/Comfortaa-VariableFont_wght.ttf"); //ORIGINAL
  //src: url("../assets/fonts/Poppins-Regular.ttf");
  //src: url("../assets/fonts/Ubuntu-Regular.ttf"); //change url accordingly
  //src: url("../assets/fonts/Lato-Regular.ttf"); //CHEVERE
  src: url("../assets/fonts/BAHNSCHRIFT.TTF"); //SUSANA

}




:root {
  .my-custom-class {

    &::part(content) {
      width: auto!important;
    }
    .popover-content.sc-ion-popover-md {

      --ion-color-primary: #64c63f!important;
      --ion-color-primary-rgb: 100,198,63!important;
      --ion-color-primary-contrast: #ffffff!important;
      --ion-color-primary-contrast-rgb: 255,255,255!important;
      --ion-color-primary-shade: #58ae37!important;
      --ion-color-primary-tint: #74cc52!important;

      --ion-color-secondary: #42b3c2!important;
      --ion-color-secondary-rgb: 66,179,194!important;
      --ion-color-secondary-contrast: #ffffff!important;
      --ion-color-secondary-contrast-rgb: 255,255,255!important;
      --ion-color-secondary-shade: #3a9eab!important;
      --ion-color-secondary-tint: #55bbc8!important;
  }
  }
  .my-custom-class2 {

    &::part(content) {
      width: auto!important;
    }

    .popover-content.sc-ion-popover-md {

      --ion-color-primary: #64c63f!important;
      --ion-color-primary-rgb: 100,198,63!important;
      --ion-color-primary-contrast: #ffffff!important;
      --ion-color-primary-contrast-rgb: 255,255,255!important;
      --ion-color-primary-shade: #58ae37!important;
      --ion-color-primary-tint: #74cc52!important;

      --ion-color-secondary: #42b3c2!important;
      --ion-color-secondary-rgb: 66,179,194!important;
      --ion-color-secondary-contrast: #ffffff!important;
      --ion-color-secondary-contrast-rgb: 255,255,255!important;
      --ion-color-secondary-shade: #3a9eab!important;
      --ion-color-secondary-tint: #55bbc8!important;
  }
  }



  ion-menu-button.md.button.in-toolbar.in-toolbar-color.ion-activatable.ion-focusable.hydrated {
    color: white;
  }

  span.alert-button-inner.sc-ion-alert-md {
    color: greenyellow;
  }
  .alert-radio-label.sc-ion-alert-md {
    white-space: normal !important;
  }
  .fc-event-title {
    color: white;
  }

a.fc-col-header-cell-cushion {
  color: white;
  font-size: large;
  text-transform: uppercase;
}



.fc-event-time{
opacity: 0;
}
td.fc-daygrid-day.fc-day.fc-day-fri.fc-day-today {
  background-color: rgb(135,206,250,0.5);
}

.fc .fc-daygrid-day-number {
  position: relative;
  z-index: 4;
  color: powderblue;
  font-size: large;
  padding: 4px;
}

  .swiper-slide.swiper-slide-active {
    text-align: center !important;
  }
  .example-container {
    height: 500px;
    overflow: auto;
  }

  table {
    width: 100%;
    background: transparent!important;
  }
  table th {
    color: rgb(255, 255, 255);
    border: 1px solid #3f3f3f;
    background: #3f3f3f;
    padding: 15px;
  }
  .mat-form-field {
    font-size: 14px;
    width: 100%;
    color: whitesmoke;
    caret-color:#37d699!important;
    background: #3f3f3f!important;
  }
  .mat-focused .mat-form-field-ripple {
    /*change color of underline when focused*/
    background-color: #37d699!important;
  }
  .mat-focused .mat-form-field-label {
    /*change color of label when focused*/
    color: whitesmoke !important;
   }
  .mat-input-element{
    caret-color:#37d699!important;
  }
  table td {
    color: rgb(222, 222, 222);
    border: 1px solid #3f3f3f;
    padding: 15px;
  }
  // --ion-font-family: ‘AppFont’
  /** primary **/
  --ion-font-family: "AppFont";

  body {
    --ion-color-primary: #073248;
    --ion-color-primary-rgb: 7, 50, 72;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #062c3f;
    --ion-color-primary-tint: #20475a;

    --ion-color-secondary: #09b7f1;
    --ion-color-secondary-rgb: 9, 183, 241;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #08a1d4;
    --ion-color-secondary-tint: #22bef2;

    --ion-color-tertiary: #37d699;
    --ion-color-tertiary-rgb: 55, 214, 153;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #30bc87;
    --ion-color-tertiary-tint: #4bdaa3;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: linear-gradient(0deg, #1d8bc5, #37dfa2) !important;
    --ion-color-light-rgb: linear-gradient(0deg, #1d8bc5, #37dfa2) !important;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: linear-gradient(0deg, #0c6d9f, #27bd86) !important;
    --ion-color-light-tint: linear-gradient(0deg, #0c6d9f, #27bd86) !important;
  }


}
@media (prefers-color-scheme: dark) {
  .popover-content.sc-ion-popover-md {
    width: auto;

    --ion-color-primary: #64c63f!important;
    --ion-color-primary-rgb: 100,198,63!important;
    --ion-color-primary-contrast: #ffffff!important;
    --ion-color-primary-contrast-rgb: 255,255,255!important;
    --ion-color-primary-shade: #58ae37!important;
    --ion-color-primary-tint: #74cc52!important;

    --ion-color-secondary: #42b3c2!important;
    --ion-color-secondary-rgb: 66,179,194!important;
    --ion-color-secondary-contrast: #ffffff!important;
    --ion-color-secondary-contrast-rgb: 255,255,255!important;
    --ion-color-secondary-shade: #3a9eab!important;
    --ion-color-secondary-tint: #55bbc8!important;
}
  /*
   * Dark Colors
   * -------------------------------------------
   */

   ion-card-content th {
    color: rgb(255, 255, 255);
  }

  ion-card-content td {
    color: rgb(222, 222, 222);
  }

  body.dark {
    --ion-color-primary: #073248;
    --ion-color-primary-rgb: 7, 50, 72;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #062c3f;
    --ion-color-primary-tint: #20475a;

    --ion-color-secondary: #09b7f1;
    --ion-color-secondary-rgb: 9, 183, 241;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #08a1d4;
    --ion-color-secondary-tint: #22bef2;

    --ion-color-tertiary: #37d699;
    --ion-color-tertiary-rgb: 55, 214, 153;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #30bc87;
    --ion-color-tertiary-tint: #4bdaa3;
    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: linear-gradient(0deg, #1d8bc5, #37dfa2) !important;
    --ion-color-light-rgb: linear-gradient(0deg, #1d8bc5, #37dfa2) !important;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: linear-gradient(0deg, #0c6d9f, #27bd86) !important;
    --ion-color-light-tint: linear-gradient(0deg, #0c6d9f, #27bd86) !important;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body.dark {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}


@media (prefers-color-scheme: light) {
  .popover-content.sc-ion-popover-md {
    width: auto;

    --ion-color-primary: #64c63f!important;
    --ion-color-primary-rgb: 100,198,63!important;
    --ion-color-primary-contrast: #ffffff!important;
    --ion-color-primary-contrast-rgb: 255,255,255!important;
    --ion-color-primary-shade: #58ae37!important;
    --ion-color-primary-tint: #74cc52!important;

    --ion-color-secondary: #42b3c2!important;
    --ion-color-secondary-rgb: 66,179,194!important;
    --ion-color-secondary-contrast: #ffffff!important;
    --ion-color-secondary-contrast-rgb: 255,255,255!important;
    --ion-color-secondary-shade: #3a9eab!important;
    --ion-color-secondary-tint: #55bbc8!important;
}
  :root {
    --fc-border-color: #FFFF;
    --fc-daygrid-event-dot-width: 5px;
  }
  /*
   * Dark Colors
   * -------------------------------------------
   */

  ion-card-content th {
    color: rgb(255, 255, 255);
  }

  ion-card-content td {
    color: rgb(222, 222, 222);
  }

  body.dark {
    --ion-color-primary: #073248;
    --ion-color-primary-rgb: 7, 50, 72;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #062c3f;
    --ion-color-primary-tint: #20475a;

    --ion-color-secondary: #09b7f1;
    --ion-color-secondary-rgb: 9, 183, 241;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #08a1d4;
    --ion-color-secondary-tint: #22bef2;

    --ion-color-tertiary: #37d699;
    --ion-color-tertiary-rgb: 55, 214, 153;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #30bc87;
    --ion-color-tertiary-tint: #4bdaa3;
    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: linear-gradient(0deg, #1d8bc5, #37dfa2) !important;
    --ion-color-light-rgb: linear-gradient(0deg, #1d8bc5, #37dfa2) !important;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: linear-gradient(0deg, #0c6d9f, #27bd86) !important;
    --ion-color-light-tint: linear-gradient(0deg, #0c6d9f, #27bd86) !important;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body.dark {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}


/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
